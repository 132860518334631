import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-grandiaziende-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { IAddressType } from "@vfit/shared/models";
/**
 * This method calls the searchPickUpLocationService.
 * This methods is able to find the pickup points related to the address.
 * Method: POST
 * @param payload
 * @param customOptions
 * @returns
 */ var searchPickUpLocationsService = function(payload, customOptions) {
    return nextClient.post("".concat(API.CARE_V1, "/").concat(API.SEARCH_PICKUP_LOCATIONS), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook for run searchPickUpLocationsService using the addressID.
 * @returns
 */ export var useSearchPickUpLocations = function() {
    var queryClient = useQueryClient();
    var keyString = "searchPickUpLocations";
    return useMutation([
        "searchPickUpLocationsMutation"
    ], function(param) {
        var id = param.id, options = param.options;
        var ref, ref1, ref2;
        var installationAddress = getFromLocalStorageByKey("createdAddress");
        var customerData = getFromLocalStorageByKey("customerData");
        var contactMedium = (customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : (ref1 = ref.owningIndividual) === null || ref1 === void 0 ? void 0 : ref1.contactMedium) || [];
        var postalAddress = contactMedium === null || contactMedium === void 0 ? void 0 : contactMedium.find(function(contact) {
            return contact.type == "postalAddress";
        });
        var addressId = ((ref2 = installationAddress) === null || ref2 === void 0 ? void 0 : ref2.id) || (postalAddress === null || postalAddress === void 0 ? void 0 : postalAddress.id);
        if (id) {
            addressId = id;
        }
        var payload;
        switch(options){
            case IAddressType.PP:
                payload = {
                    addressId: addressId,
                    geoLocations: {},
                    radius: 30,
                    additionalIdType: "PICKUP_POINTX9"
                };
                keyString = "searchPickUpStoreLocations";
                break;
            case IAddressType.PO:
                payload = {
                    addressId: addressId,
                    geoLocations: {},
                    radius: 30,
                    additionalIdType: "POSTAL_OFFICEX9"
                };
                break;
            default:
                payload = {
                    addressId: addressId,
                    geoLocations: {},
                    radius: 30,
                    additionalIdType: "POSTAL_OFFICEX9"
                };
        }
        return searchPickUpLocationsService(payload);
    }, {
        onMutate: function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(data) {
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            if (data === null || data === void 0 ? void 0 : data.id) {
                                keyString += data.id;
                            }
                        case 1:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function(data) {
                return _ref.apply(this, arguments);
            };
        }(),
        onSuccess: function(data) {
            localStorage.setItem(keyString, JSON.stringify(data));
            queryClient.setQueryData(keyString, data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
        }
    });
};
