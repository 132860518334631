import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-grandiaziende-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useAddressGeocoder, loaderObj } from "@vfit/shared/hooks";
import { Loader } from "@googlemaps/js-api-loader";
import { LoggerInstance } from "@vfit/shared/providers";
import * as yup from "yup";
import { ICoverageToolType } from "./coverageTool.models";
var GetAddressObjectFromString = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(text) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.t0 = Promise;
                    _ctx.next = 3;
                    return useAddressGeocoder(text);
                case 3:
                    _ctx.t1 = _ctx.sent;
                    return _ctx.abrupt("return", _ctx.t0.resolve.call(_ctx.t0, _ctx.t1));
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function GetAddressObjectFromString(text) {
        return _ref.apply(this, arguments);
    };
}();
var retrieveAddress = function(add) {
    var addressString = "";
    Object.values(add).map(function(el) {
        if (el) addressString = addressString.concat(el, ", ");
        return null;
    });
    return addressString;
};
var checkCoverageToolType = function(mutateLocation) {
    var typeCoverageTool = ICoverageToolType.AUTO;
    LoggerInstance.debug("Mutate location", mutateLocation);
    // eslint-disable-next-line no-param-reassign
    delete mutateLocation.completeAddress;
    Object.keys(mutateLocation).forEach(function(key) {
        if (!mutateLocation[key]) typeCoverageTool = ICoverageToolType.MANUAL;
    });
    return typeCoverageTool;
};
var checkAddressFromValue = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(text) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.t0 = Promise;
                    _ctx.next = 3;
                    return GetAddressObjectFromString(text);
                case 3:
                    _ctx.t1 = _ctx.sent;
                    return _ctx.abrupt("return", _ctx.t0.resolve.call(_ctx.t0, _ctx.t1));
                case 5:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function checkAddressFromValue(text) {
        return _ref.apply(this, arguments);
    };
}();
function getAddressFromLatLng(lat, lng) {
    return _getAddressFromLatLng.apply(this, arguments);
}
function _getAddressFromLatLng() {
    _getAddressFromLatLng = _async_to_generator(regeneratorRuntime.mark(function _callee(lat, lng) {
        var mapsLoader, google, geocoder, latlng;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    mapsLoader = new Loader(loaderObj);
                    _ctx.next = 3;
                    return mapsLoader.load();
                case 3:
                    google = _ctx.sent;
                    if (!(google !== undefined)) {
                        _ctx.next = 8;
                        break;
                    }
                    geocoder = new google.maps.Geocoder();
                    latlng = new google.maps.LatLng(lat, lng);
                    return _ctx.abrupt("return", new Promise(function(resolve, reject) {
                        geocoder.geocode({
                            location: latlng
                        }, function(results, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                if (results !== null) {
                                    var filteredResults = results.filter(function(result) {
                                        return result.address_components.length === 8;
                                    });
                                    if (filteredResults.length !== 0) {
                                        resolve(filteredResults[0]);
                                    } else resolve(results[0]);
                                } else {
                                    LoggerInstance.debug("No results found");
                                }
                            } else {
                                LoggerInstance.debug("Geocoder failed due to: ", status);
                            }
                            // eslint-disable-next-line prefer-promise-reject-errors
                            reject("Geolocalization error");
                        });
                    }));
                case 8:
                    return _ctx.abrupt("return", null);
                case 9:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return _getAddressFromLatLng.apply(this, arguments);
}
var getCoverageInputValidation = function(coverageToolConfig) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11;
    return yup.object({
        placeId: yup.string().required(""),
        city: yup.string().min(1, (coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref = coverageToolConfig.coveragetool) === null || ref === void 0 ? void 0 : (ref1 = ref.errorMessage) === null || ref1 === void 0 ? void 0 : ref1.error0) || " ").matches(/^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ'"+\s]*$/g, (coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref2 = coverageToolConfig.coveragetool) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.errorMessage) === null || ref3 === void 0 ? void 0 : ref3.error1) || " "),
        //  .required(coverageToolConfig.coveragetool.errorMessage.error0),
        street: yup.string().min(1, (coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref4 = coverageToolConfig.coveragetool) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.errorMessage) === null || ref5 === void 0 ? void 0 : ref5.error0) || " ").matches(/^[a-zA-Z+"-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g, (coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref6 = coverageToolConfig.coveragetool) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.errorMessage) === null || ref7 === void 0 ? void 0 : ref7.error1) || " "),
        // .required(coverageToolConfig.coveragetool.errorMessage.error0),
        streetNumber: yup.string().test("rangeTest", (coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref8 = coverageToolConfig.coveragetool) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.errorMessage) === null || ref9 === void 0 ? void 0 : ref9.error1) || " ", function(value) {
            if (value === null || value === void 0 ? void 0 : value.match(/^[1-9][0-9]{0,4}(?:\/[0-9]{1,4})?[a-zA-Z]?$/)) {
                return true;
            }
            return false;
        }).required(coverageToolConfig === null || coverageToolConfig === void 0 ? void 0 : (ref10 = coverageToolConfig.coveragetool) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.errorMessage) === null || ref11 === void 0 ? void 0 : ref11.error0)
    }).required();
};
var composeAddress = function(value) {
    var ref, ref1;
    var address_components = value.address_components;
    var address = {
        street: "",
        streetNumber: "",
        city: "",
        province: "",
        postalCode: "",
        latitude: "",
        longitude: "",
        region: ""
    };
    address_components.forEach(function(v) {
        if (v.types.includes("route")) address.street = v.long_name;
        else if (v.types.includes("administrative_area_level_3")) address.city = v.long_name;
        else if (v.types.includes("administrative_area_level_2")) address.province = v.short_name;
        else if (v.types.includes("administrative_area_level_1")) address.region = v.short_name;
        else if (v.types.includes("postal_code")) address.postalCode = v.long_name;
        else if (v.types.includes("street_number")) address.streetNumber = v.long_name;
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    address.placeId = value.place_id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    address.latitude = value === null || value === void 0 ? void 0 : (ref = value.geometry) === null || ref === void 0 ? void 0 : ref.location.lat();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    address.longitude = value === null || value === void 0 ? void 0 : (ref1 = value.geometry) === null || ref1 === void 0 ? void 0 : ref1.location.lng();
    return address;
};
var organizeEditAddressManual = function(cmsData) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12;
    return {
        addressInputCard: {
            title: "",
            description: "",
            labels: {
                title: (cmsData === null || cmsData === void 0 ? void 0 : (ref = cmsData.editaddressconfig) === null || ref === void 0 ? void 0 : ref.title) || "Cambia indirizzo",
                street: (cmsData === null || cmsData === void 0 ? void 0 : (ref1 = cmsData.editaddressconfig) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.labels) === null || ref2 === void 0 ? void 0 : ref2.street) || "Indirizzo",
                streetNumber: (cmsData === null || cmsData === void 0 ? void 0 : (ref3 = cmsData.editaddressconfig) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.labels) === null || ref4 === void 0 ? void 0 : ref4.streetNumber) || "Numero civico",
                displayStateOrProvince: (cmsData === null || cmsData === void 0 ? void 0 : (ref5 = cmsData.editaddressconfig) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.labels) === null || ref6 === void 0 ? void 0 : ref6.stateOrProvince) || "Provincia",
                city: (cmsData === null || cmsData === void 0 ? void 0 : (ref7 = cmsData.editaddressconfig) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.labels) === null || ref8 === void 0 ? void 0 : ref8.city) || "Comune",
                postalCode: (cmsData === null || cmsData === void 0 ? void 0 : (ref9 = cmsData.editaddressconfig) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.labels) === null || ref10 === void 0 ? void 0 : ref10.postalCode) || "CAP"
            },
            buttonLabel: (cmsData === null || cmsData === void 0 ? void 0 : (ref11 = cmsData.editaddressconfig) === null || ref11 === void 0 ? void 0 : ref11.submitButton_manual) || "AGGIORNA MANUALMENTE"
        },
        addressErrors: {
            city: {
                min: "Campo non valido",
                matchRegex: "Campo non valido",
                required: "Campo obbligatorio"
            },
            postalCode: {
                min: "Campo non valido",
                matchRegex: "Campo non valido",
                required: "Campo obbligatorio"
            },
            stateOrProvince: {
                min: "Campo non valido",
                matchRegex: "Campo non valido",
                required: "Campo obbligatorio"
            },
            street: {
                min: "Campo non valido",
                matchRegex: "Campo non valido",
                required: "Campo obbligatorio"
            },
            streetNumber: {
                min: "Campo non valido",
                matchRegex: "Campo non valido",
                required: "Campo obbligatorio"
            }
        },
        genericErrorAddress: (cmsData === null || cmsData === void 0 ? void 0 : (ref12 = cmsData.editaddressconfig) === null || ref12 === void 0 ? void 0 : ref12.apiError) || "Non \xe8 stato possibile trovare l'indirizzo. La preghiamo di ricontrollare i dati inseriti."
    };
};
export { checkCoverageToolType, retrieveAddress, checkAddressFromValue, getAddressFromLatLng, getCoverageInputValidation, composeAddress, organizeEditAddressManual };
